import React from "react";

const Fish = () => {
  return (
    <>
      <main className="main">
        <div className="main__hug">
          <div className="main__frame wrap">
            <iframe
              style={{ width: "100%", height: "100vh" }}
              src="https://loader.swintt-stg.net/staging/gameloader?ts=1712061315268&amp;operatorId=SWINTT-TEST&amp;gameId=15720&amp;languageId=en&amp;client=desktop&amp;funMode=1%22"></iframe>
          </div>
        </div>
      </main>
    </>
  );
};

export default Fish;
